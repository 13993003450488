import { GroupBase, OptionsOrGroups } from 'chakra-react-select';
import { useCallback, useRef } from 'react';

type ISearchFunction<Option = unknown, Group extends GroupBase<Option> = GroupBase<Option>> = (
  search: string,
) => Promise<OptionsOrGroups<Option, Group>>;

export const useDebouncedSearch = <
  Option = unknown,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchFunction: (...args: any[]) => unknown,
): ISearchFunction<Option, Group> => {
  const triggered = useRef<boolean>(false);
  const timer = useRef<NodeJS.Timeout | null>(null);

  return useCallback(
    (search: string) =>
      new Promise(res => {
        triggered.current = true;
        const p = new Promise((_res, _err) => {
          if (triggered.current && timer.current) clearTimeout(timer.current);
          timer.current = setTimeout(async () => {
            triggered.current = false;
            const r = await searchFunction(search);
            _res(r);
          }, 500);
        });
        p.then(x => res(x as Option[]));
      }),
    [searchFunction],
  );
};
