import { useCallback } from 'react';

import { type ISearchUsersRequest, useLazySearchUsersQuery } from '@/store/services/users';

import { getUserOption } from './helpers';

export function useUserSearch(params: ISearchUsersRequest) {
  const [searchUsers] = useLazySearchUsersQuery();

  return useCallback(
    async (search: string) => {
      const result = await searchUsers(
        {
          ...params,
          search,
        },
        true,
      ).unwrap();
      return result.map(getUserOption);
    },
    [searchUsers, params],
  );
}
