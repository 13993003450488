import { ProfileSettingsEntry, ProfileSettingsInput } from '@epitech/ops-panoramix-users-types';
import { createSlice } from '@reduxjs/toolkit';

import { eventsApi } from './events';
import { usersApi } from './users';

export type ModuleColors = { [key: string]: { light: string; dark: string } };

export type SettingsState = {
  moduleColors: ModuleColors;
  profile: ProfileSettingsEntry;
};

const DEFAULT_STATE: SettingsState = {
  moduleColors: {},
  profile: Object.assign({}, new ProfileSettingsEntry()),
};

const generateRGBColor = (light: boolean = true) => {
  let sat: number = 30;
  let bright: number = 30;

  if (light === true) {
    sat = 50;
    bright = 70;
  }

  const tint: number = Math.floor(Math.random() * 361);
  const saturation: number = Math.floor(Math.random() * 21) + sat; // 30
  const brightness: number = Math.floor(Math.random() * 21) + bright; // 30
  const rgbColor = hslToRgb(tint / 360, saturation / 100, brightness / 100);

  return `rgba(${rgbColor.r},${rgbColor.g},${rgbColor.b}, 1)`;
};

const hslToRgb = (h: number, s: number, l: number) => {
  if (s === 0) {
    return { r: l, g: l, b: l };
  }

  const hueToRgb = (p: number, q: number, tt: number): number => {
    let t = tt;
    if (t < 0) t += 1;
    if (t > 1) t -= 1;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 2) return q;
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
    return p;
  };

  const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
  const p = 2 * l - q;

  const r = Math.round(hueToRgb(p, q, h + 1 / 3) * 255);
  const g = Math.round(hueToRgb(p, q, h) * 255);
  const b = Math.round(hueToRgb(p, q, h - 1 / 3) * 255);

  return { r, g, b };
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: DEFAULT_STATE,
  reducers: {
    updateProfileSettings: (state, action: { payload: Partial<ProfileSettingsInput> }) => {
      state.profile = { ...state.profile, ...action.payload };
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      eventsApi.endpoints.getEventsWithRegisteredStatus.matchFulfilled,
      (state, { payload }) => {
        const res: ModuleColors = {};
        payload.forEach(event => {
          if (event.moduleRef) {
            if (
              !state.moduleColors[event.moduleRef._id] ||
              !state.moduleColors[event.moduleRef._id].light ||
              !state.moduleColors[event.moduleRef._id].dark
            )
              res[event.moduleRef._id] = {
                light: generateRGBColor(true),
                dark: generateRGBColor(false),
              };
          }
        });
        if (Object.keys(res).length > 0)
          return { ...state, moduleColors: { ...state.moduleColors, ...res } };

        return state;
      },
    );
    builder.addMatcher(usersApi.endpoints.login.matchFulfilled, (state, { payload }) => {
      state.profile = { ...state.profile, ...payload.user.profileSettings };
    });
    builder.addMatcher(usersApi.endpoints.updateSettings.matchPending, (state, { meta }) => {
      state.profile = { ...state.profile, ...meta.arg.originalArgs };
    });
  },
});

export const { updateProfileSettings } = settingsSlice.actions;
