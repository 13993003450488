import { Flex, StackProps, Text } from '@chakra-ui/react';
import type { UserRef } from '@epitech/ops-panoramix-types';
import React from 'react';

import { UserAvatar } from './UserAvatar';

export type UserItemProps = {
  /** Spacing between the Avatar and the name. Defaults to 2. (Check chakra styles to convert in `px`) */
  value: UserRef;
} & StackProps;

function _UserItem({ value: user, spacing = 2, ...stackProps }: UserItemProps): React.ReactElement {
  const formattedLogin = user.login.split('@')[0].replace('.', ' ');
  return (
    <Flex gap={spacing} alignItems="center" {...stackProps}>
      <UserAvatar user={user} size="xs" />
      <Text title={`${user.firstName} ${user.lastName}`} overflow="hidden" textOverflow={"ellipsis"} textTransform="capitalize">{formattedLogin}</Text>
    </Flex>
  );
}

export const UserItem = React.memo<UserItemProps>(_UserItem);
