import { AsyncSelect as BaseAsyncSelect, GroupBase } from 'chakra-react-select';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { mergeChakraStyles } from '@/config/theme';
import { useDebouncedSearch } from '@/lib/hooks/useDebouncedSearch';

import { getSelectDefaultComponentsOverride } from '../shared';
import { IAsyncSelectProps } from './Async.types';

export function AsyncSelectBase<Option, IsMulti extends boolean, Group extends GroupBase<Option>>({
  loadOptions,
  selectRef,
  components = {},
  isSearchable = true,
  cacheOptions = true,
  defaultOptions = true,
  isClearable = true,
  backspaceRemovesValue = false,
  isInvalid = false,
  size = 'md',
  displayIcon = true,
  chakraStyles = {},
  ...otherProps
}: IAsyncSelectProps<Option, IsMulti, Group>) {
  const mergedStyles = mergeChakraStyles<Option, IsMulti, Group>(chakraStyles);
  const { t } = useTranslation('components/shared/Select');

  const overridedComponents = React.useMemo(
    () => ({
      ...getSelectDefaultComponentsOverride<Option, IsMulti, Group>(),
      ...components,
    }),
    [components],
  );

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const debouncedLoadOptions = useDebouncedSearch<Option, Group>(loadOptions!);

  return (
    <BaseAsyncSelect
      ref={selectRef}
      loadOptions={debouncedLoadOptions}
      cacheOptions={cacheOptions}
      defaultOptions={defaultOptions}
      placeholder={t('placeholder_label')}
      loadingMessage={() => t('loading_msg')}
      isSearchable={isSearchable}
      isClearable={isClearable}
      backspaceRemovesValue={backspaceRemovesValue}
      size={size}
      components={{
        ...overridedComponents,
        DropdownIndicator: displayIcon ? overridedComponents.DropdownIndicator : undefined,
      }}
      {...otherProps}
      chakraStyles={mergedStyles}
    />
  );
}
