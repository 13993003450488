import { ComponentMultiStyleConfig } from '@chakra-ui/react';

export const Modal: ComponentMultiStyleConfig = {
  parts: ['dialog', 'body'],
  baseStyle: {
    dialog: {
      layerStyle: 'base',
    },
    body: {
      py: 0,
    },
  },
  variants: {
    wide: {
      dialog: {
        maxW: { base: '100%', lg: '65em', xl: '80em' },
        minW: 'initial',
      },
    },
  },
};
