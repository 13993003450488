import { Button, ButtonGroup, Flex, Grid, GridItem, Icon, IconButton, Text } from '@chakra-ui/react';
import { CalendarApi } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import React, { useCallback } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { FiChevronDown, FiChevronLeft, FiChevronRight, FiPlus } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';

import { getDateFnsLanguage } from '@/config/localization';

import { Can } from '../ui-elements/Can';
import { CalendarMenu } from './Menu';

interface IProps {
  goToDate: (e: Date) => void;
  calendarRef: React.RefObject<FullCalendar>;
  isTimeline: boolean;
  onWorkingHoursChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCalendarViewChange: (e: string) => void;
  locale: string;
}

export const CalendarHeader: React.FC<IProps> = React.memo(
  ({ goToDate, calendarRef, isTimeline, onWorkingHoursChange, onCalendarViewChange, locale }) => {
    const calendarApi: CalendarApi | null = calendarRef.current
      ? calendarRef.current.getApi()
      : null;
    const [calendarTitle, setCalendarTitle] = React.useState(calendarApi?.view.title);
    const navigate = useNavigate();
    const location = useLocation();

    const onPrevClick = useCallback(() => calendarApi?.prev(), [calendarApi]);

    const onTodayClick = useCallback(() => calendarApi?.today(), [calendarApi]);

    const onNextClick = useCallback(() => calendarApi?.next(), [calendarApi]);

    const onEventAdd = useCallback(
      () =>
        navigate({
          pathname: '/calendar/events/create',
          search: location.search,
        }),
      [location.search, navigate],
    );

    const onViewChange = useCallback(
      (view: string) => {
        if (view) {
          calendarApi?.changeView(view);
          onCalendarViewChange(view);
        }
      },
      [calendarApi, onCalendarViewChange],
    );

    const initLanguage = useCallback((languageStr: string) => {
      const language = getDateFnsLanguage(languageStr);
      registerLocale(languageStr, language);
    }, []);

    React.useEffect(() => {
      setCalendarTitle(calendarApi?.view.title);
    }, [calendarApi?.view.title, locale]);

    const { t } = useTranslation('layouts/calendar');
    const { t: date_t } = useTranslation('components/shared');
    initLanguage(date_t('localeDateFns'));

    return calendarApi ? (
      <Grid
        layerStyle="base"
        borderRadius={0}
        shadow="none"
        alignItems="center"
        p={4}
        pt={0}
        templateColumns={{
          base: 'repeat(1, 1fr)',
          md: 'repeat(3, 1fr)',
        }}
      >
        <GridItem lineHeight="48px" textAlign={{ base: 'center', md: 'left' }}>
          <ButtonGroup verticalAlign="middle" spacing="2" size="sm" isAttached colorScheme="shadow">
            <Button leftIcon={<Icon as={FiChevronLeft} />} onClick={onPrevClick}>
              {t('back')}
            </Button>
            <Button onClick={onTodayClick}>{t('today')}</Button>
            <Button rightIcon={<Icon as={FiChevronRight} />} onClick={onNextClick}>
              {t('next')}
            </Button>
          </ButtonGroup>
        </GridItem>
        <GridItem>
          <ReactDatePicker
            dateFormat={date_t('date')}
            locale={date_t('localeDateFns')}
            portalId='root-portal'
            popperPlacement='bottom'
            selected={new Date()}
            shouldCloseOnSelect={true}
            title={t('gotodate')}
            customInput={
              <Flex height="48px" minW="230px" justifyContent={'center'} alignItems={'center'}>
                <Button
                variant="white"
                size={"sm"}>{
                  <Text
                    fontSize={20}
                    fontWeight="bold"
                    lineHeight="48px"
                    textAlign="center"
                    verticalAlign="middle"
                  >
                  {calendarTitle}
                  </Text>
                  }
                  <Icon marginLeft={'2'} as={FiChevronDown} />
              </Button>
            </Flex>
            }
            onChange={goToDate}
          />
        </GridItem>
        <GridItem textAlign={{ base: 'center', md: 'right' }}>
          <ButtonGroup isAttached size="sm" colorScheme="shadow">
            {!isTimeline ? (
              <Can I="create" an="event">
                <IconButton
                  icon={<Icon as={FiPlus} />}
                  onClick={onEventAdd}
                  aria-label={t('create_event')}
                >
                  {t('add')}
                </IconButton>
              </Can>
            ) : null}
            <CalendarMenu
              view={calendarApi.view.type}
              onViewChange={onViewChange}
              isTimeline={isTimeline}
              onWorkingHoursChange={onWorkingHoursChange}
            />
          </ButtonGroup>
        </GridItem>
      </Grid>
    ) : null;
  },
);
