import type { EventRef } from '@epitech/ops-panoramix-types';
import type { OptionBase } from 'chakra-react-select';
import { useCallback } from 'react';

import { sanitizeDateObject } from '@/lib/helpers/events.helpers';
import { type ISearchEventsRequest, useLazySearchEventsQuery } from '@/store/services/events';

export interface IEventOption extends OptionBase {
  label: string;
  value: EventRef;
}

const getEventOption = (eventRef: EventRef): IEventOption => ({
  label: eventRef.title,
  value: eventRef,
});

export function useEventSearch(params?: ISearchEventsRequest) {
  const [searchEvents] = useLazySearchEventsQuery();

  return useCallback(
    async (search: string) => {
      const result = await searchEvents({ ...params, search }, true).unwrap();
      return result.map(sanitizeDateObject).map(getEventOption);
    },
    [searchEvents, params],
  );
}
