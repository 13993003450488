import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Button, Center, Heading, VStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaMicrosoft } from 'react-icons/fa';
import { createSearchParams, Navigate, useLocation } from 'react-router-dom';

import { loginRequest } from '@/config/authentication';
import { environment } from '@/config/environment';

import { DEFAULT_CALENDAR_PARAMS, DEFAULT_ROOMS_PARAMS } from './Calendar/utils';

type LocationState = {
  from?: {
    pathname: string;
    search: string;
  };
};

export const AuthenticationPage: React.FC = () => {
  const { t } = useTranslation('pages/Authentication');
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const from = (location?.state as LocationState)?.from;

  const handleLogin = () => instance.loginPopup(loginRequest);

  let pathname = '/calendar';
  let search = createSearchParams(DEFAULT_CALENDAR_PARAMS).toString();

  if (from?.pathname.includes('/calendar')) {
    pathname = from.pathname;
    if (from.search) {
      search = from.search;
    }
  }
  else if (from?.pathname.includes('/rooms')) {
    pathname = from.pathname;
    search = from.search || createSearchParams(DEFAULT_ROOMS_PARAMS).toString();
  }

  return isAuthenticated ? (
    <Navigate
      to={{
        pathname,
        search,
      }}
    />
  ) : (
    <Center h="full" w="full">
      <VStack spacing={8}>
        <Heading size="2xl">{environment.NAME}</Heading>
        <Button size="lg" rightIcon={<FaMicrosoft />} colorScheme="blue" onClick={handleLogin}>
          {t('connect')}
        </Button>
      </VStack>
    </Center>
  );
};
