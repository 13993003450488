const bgColors = {
  mainBgColor: {
    default: 'gray.50',
    _dark: 'gray.800',
  },
  primaryBgColor: {
    default: 'gray.50',
    _dark: 'gray.800',
  },
  secondBgColor: {
    default: 'gray.100',
    _dark: 'gray.700',
  },
  thirdBgColor: {
    default: 'white',
    _dark: 'gray.700',
  },
  containerBgColor: {
    default: 'white',
    _dark: 'gray.600',
  },
  buttonBgColorDark: {
    default: 'blackAlpha.500',
    _dark: 'whiteAlpha.200',
  },
  buttonBgColorDarkHover: {
    default: 'blackAlpha.600',
    _dark: 'whiteAlpha.300',
  },
  buttonBgColorDarkActive: {
    default: 'blackAlpha.700',
    _dark: 'whiteAlpha.400',
  },
  itemBgColorHover: {
    default: 'gray.200',
    _dark: 'whiteAlpha.200',
  },
  selectedBgColor: {
    default: '#fffadf',
    _dark: 'green.800',
  },
  disabledBgColor: {
    default: 'gray.200',
    _dark: 'gray.800',
  },
  greyLightDarkBgColor: {
    default: 'gray.100',
    _dark: 'gray.800',
  },
};

const borderColors = {
  primaryBorderColor: {
    default: '#ddd',
    _dark: 'black',
  },
  buttonBorderColorAfter: {
    default: '#718096',
    _dark: 'rgba(255,255,255,0.92)',
  },
  inputBorderColor: {
    default: 'gray.200',
    _dark: 'gray.200',
  },
  separatorBorderColor: {
    default: 'gray.400',
    _dark: 'white',
  },
  separatorSecondaryBorderColor: {
    default: 'gray.200',
    _dark: 'whiteAlpha.200',
  },
  separatorThirdBorderColor: {
    default: 'gray.100',
    _dark: 'grat.700',
  },
};

const fontColors = {
  primaryFontColor: {
    default: 'gray.700',
    _dark: 'whiteAlpha.900',
  },
  secondFontColor: {
    default: 'gray.500',
    _dark: 'whiteAlpha.700',
  },
  blueGreenFontColor: {
    default: 'blue.300',
    _dark: 'green.500',
  },
};

const placeholderColors = {
  mainPlaceholderColor: {
    default: 'gray.400',
    _dark: 'gray.400',
  },
};

const inputColors = {
  primaryInputColor: {
    default: 'white',
    _dark: 'gray.400',
  },
};

const baseColors = {
  selectIconBgColor: {
    default: 'gray.100',
    _dark: 'gray.700',
  },
  selectIconColor: {
    default: 'gray.400',
    _dark: 'white',
  },
  selectIconFocusedColor: {
    default: 'gray.800',
    _dark: 'white',
  },
};

const datepickerColors = {
  datepickerColor: {
    default: '#000',
    _dark: '#fff',
  },
  modalBgColor: {
    default: '#ffffff',
    _dark: 'gray.600',
  },
  borderSpecialColor: {
    default: '#cbd5e0',
    _dark: '#cbd5e0',
  },
  borderSpecialHoverColor: {
    default: '#a0aec0',
    _dark: '#a0aec0',
  },
  borderColor: {
    default: '#e2e8f0',
    _dark: '#e2e8f0',
  },
  warningBorderColor: {
    default: 'orange.300',
    _dark: 'orange.200',
  },
  bgHoverColor: {
    default: '#edf2f7',
    _dark: 'gray.800',
  },
  bgSelectedColor: {
    default: '#3182ce',
    _dark: '#3182ce',
  },
  bgSelectedHoverColor: {
    default: '#2a69ac',
    _dark: '#2a69ac',
  },
  //
  bgHeaderColor: {
    default: '#f7fafc',
    _dark: 'gray.700',
  },
};

// // if we want to override globals for a specific environment
// const override: Record<typeof environment.NODE, Record<string, SemanticValue<keyof Pseudos>>> = {
//   development: {},
//   staging: {},
//   production: {},
// };

export const semanticTokens = {
  colors: {
    ...bgColors,
    ...borderColors,
    ...fontColors,
    ...placeholderColors,
    ...inputColors,
    ...baseColors,
    ...datepickerColors,
    // ...override[environment.NODE],
  },
};
