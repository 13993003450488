import type { ModuleRef } from '@epitech/ops-panoramix-types';
import { useCallback } from 'react';

import { type ISearchModulesRequest, useLazySearchModulesQuery } from '@/store/services/modules';

import { getModuleOption, IModuleOption } from './helpers';

type GetModuleOption = (module: ModuleRef) => IModuleOption;

export function useModuleSearch(
  params?: ISearchModulesRequest,
  getOption: GetModuleOption = getModuleOption,
) {
  const [searchModules] = useLazySearchModulesQuery();

  return useCallback(
    async (search: string) => {
      const result = await searchModules(
        {
          ...params,
          search,
        },
        true,
      ).unwrap();
      return result.map(getOption);
    },
    [searchModules, params, getOption],
  );
}
