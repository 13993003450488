import {
  Box,
  Button,
  Hide,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuList,
  Portal,
  Switch,
  Text,
  useColorMode,
} from '@chakra-ui/react';
import { UserEntry } from '@epitech/ops-panoramix-users-types';
import capitalize from 'lodash/capitalize';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FiArrowLeft, FiChevronDown, FiLogOut, FiMoon, FiSun } from 'react-icons/fi';
import { useDispatch } from 'react-redux';

import { onLogasChange } from '@/lib/helpers/logas.helpers';
import { useAuth } from '@/lib/hooks/useAuth';
import useDebouncedCallback from '@/lib/hooks/useDebouncedCallback';
import useLogout from '@/lib/hooks/useLogout';
import useProfileSettings from '@/lib/hooks/useProfileSettings';
import { Stored } from '@/lib/types/utils';
import { logoutLogasUser } from '@/store/services/authentication.slice';
import { updateProfileSettings } from '@/store/services/settings.slice';
import { useUpdateSettingsMutation } from '@/store/services/users';

import { LanguageSelect } from '../LanguageSelect';
import { Can } from '../ui-elements/Can';
import { MenuItemButton } from '../ui-elements/MenuItemButton';
import { LogasActionButton } from './LogasActionButton';
import { UserAvatar } from './UserAvatar';

interface IProps {
  user: Stored<UserEntry>;
}

export const ProfileMenu: React.FC<IProps> = React.memo(({ user }) => {
  const { t } = useTranslation('components/users');
  const { colorMode } = useColorMode();
  const dispatch = useDispatch();
  const { handleLogout } = useLogout();
  const { logasUser } = useAuth();
  const profileSettings = useProfileSettings();
  const [updateSettings] = useUpdateSettingsMutation();
  // const [ syncOutlook, setSyncOutlook ] = useState(profileSettings.syncOutlook);
  const debouncedUpdateSettings = useDebouncedCallback(updateSettings, 500);

  const onChangeColorMode = useCallback(async () => {
    dispatch(updateProfileSettings({ displayTheme: colorMode === 'dark' ? 'light' : 'dark' }));
    debouncedUpdateSettings({ displayTheme: colorMode === 'dark' ? 'light' : 'dark' });
  }, [colorMode, debouncedUpdateSettings, dispatch]);

  const handleLogasLogout = useCallback(() => {
    onLogasChange(dispatch);
    dispatch(logoutLogasUser());
  }, [dispatch]);

  return (
    <Menu autoSelect={false} isLazy placement="bottom-end">
      <MenuButton as={Button} variant="ghost" rightIcon={<Icon as={FiChevronDown} />}>
        <Hide below="sm">
          <HStack>
            <UserAvatar user={user} size="sm" />
            <Text>{t('profile')}</Text>
          </HStack>
        </Hide>
      </MenuButton>
      <Portal>
        <MenuList>
          <MenuGroup title={`${capitalize(user.firstName)} ${capitalize(user.lastName)}`}>
            <MenuDivider />
            <HStack as={HStack} justify="space-between" px="3" py={2}>
              <Icon size={12} as={FiSun} />
              <Switch size="md" isChecked={colorMode === 'dark'} onChange={onChangeColorMode} />
              <Icon size={12} as={FiMoon} />
            </HStack>
            <MenuDivider />
            <Can I="login_as" an="user">
              <Box display="flex" justifyContent="space-between" px="3" py={2}>
                {t('title_logas')}
                <LogasActionButton />
              </Box>
            </Can>
            <MenuDivider />
            <LanguageSelect profileSettings={profileSettings} />
            <MenuDivider />
            {logasUser ? (
              <MenuItemButton icon={FiArrowLeft} onClick={handleLogasLogout} py={2}>
                {logasUser.firstName} {logasUser.lastName}
              </MenuItemButton>
            ) : (
              <MenuItemButton onClick={() => handleLogout()} icon={FiLogOut} py={2}>
                {t('deconnection')}
              </MenuItemButton>
            )}
          </MenuGroup>
        </MenuList>
      </Portal>
    </Menu>
  );
});
